.avatar {
  opacity: 1;
  transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);

}

.avatar::ng-deep .avatar-content:hover {
  opacity: 0.6 !important;
  cursor: pointer;
  transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
