@import 'abstracts/colors';
@import 'abstracts/variables';
@import 'base/typography';
@import 'components/loadingAnimation';
@import 'components/table';
@import 'components/errors';
@import 'components/core';
@import 'components/tabs';
@import 'components/cards';
@import 'components/avatar';
@import 'components/cookies';
@import 'layout/grid';
@import 'layout/page';
@import 'layout/core';
@import 'layout/mobile';
@import 'layout/flex';

.scroll-to-top {
  position: fixed;
  bottom: 55px;
  right: 15px;
  opacity: 0;
  transition: all .2s ease-in-out;
  z-index: 15;
}

.show-scrollTop {
  opacity: 1;
  transition: all .2s ease-in-out;
}
