html {
  height: 100%
}

body {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app-root {
  height: 100%;
}
