@import 'src/styles/abstracts/colors';
@import '../../styles/themes/bag-theme';

.left {
  float: left;
}

.right {
  float: right;
}

.fullWidth {
  width: 100%;
}

.halfWidth {
  width: 48%;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 20px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.no-margin {
  margin: 0 !important;
}

.padding-20 {
  padding: 20px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.no-vertical-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.no-padding {
  padding: 0;
}

.small-padding {
  padding: 4px;
}

.icon-spacer {
  text-align: center;
  min-width: 32px;
}

.icon-spacer-large {
  text-align: center;
  min-width: 64px;
}

.icon-spacer-x-large {
  text-align: center;
  min-width: 128px;
}

.justify-content-center {
  justify-content: center;
}

.col-centered {
  float: none;
  margin: 0 auto;
}


.form-whole-line {
  width: 100%
}

.button-bag-layout {
  color: white !important;
  background-color: mat-color($md-primary, 500) !important;
  border-color: white !important;
}

.button-bag-layout-inverted {
  color: mat-color($md-primary, 500) !important;
  background-color: white;
  border-color: mat-color($md-primary, 500) !important;
}


.mat-button-wrapper {
  padding: 0 !important;
}

.fill-svg-white {
  filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(5%) hue-rotate(123deg) brightness(102%) contrast(102%);
}

.footer-margin {
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
