.tab-limited-width {
  max-width: 1280px;
}

.tab-headline {
  text-align: center;
  font-size: x-large;
  margin: 24px 0px;
}

.tab-subtitle {
  text-align: center;
  font-size: larger;
  margin: 16px 16px 0px 16px;
}
