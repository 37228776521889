.app-loader {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.05);
}

app-root:empty + .app-loader {
  display: block;
  z-index: 100;
}

.sk-cube-grid {
  width: 100px;
  height: 100px;
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.sk-cube-grid .sk-cube {
  width: 20%;
  height: 20%;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.8s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.8s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.sk-cube-grid .grey {
  background-color: rgb(166, 166, 166);
}

.sk-cube-grid .hidden {
  visibility: hidden;
  animation: none;
}

.sk-cube-grid .red {
  background-color: rgb(176, 35, 67);
}

.sk-cube-grid .half-vertical {
  height: 50%;
}

.sk-cube-grid .half-horizontal {
  width: 50%;
  height: 100%;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
