.inactive-element {
  font-style: italic;
  background-attachment: fixed;
  background: repeating-linear-gradient(45deg,
    transparent,
    transparent 10px,
    #ffffff 10px,
    #ffffff 20px) top left fixed,
  linear-gradient(to bottom,
      #fcfcfc,
      #f2f2f2) !important;
}

.inactive-element-stronger {
  font-style: italic;
  background-attachment: fixed;
  background: repeating-linear-gradient(45deg,
    transparent,
    transparent 10px,
    #e3e3e3 10px,
    #e3e3e3 20px) top left fixed,
  linear-gradient(to bottom,
      #fcfcfc,
      #e3e3e3) !important;
}

.photo-round {
  border-radius: 50%;
}
