@import '../../styles/themes/bag-theme.scss';

body {
  font-family: Roboto, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  color: #575756;
}

.italic {
  font-style: italic;
}

.word-wrap {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.text-center {
  text-align: center;
}

.font-32 {
  font-size: 32px;
}

.headline {
  text-transform: uppercase;
  color: mat-color($md-primary, 500);
}
